import React,{Component} from 'react';

class TeacherProfile extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return(
            <div>
                
            </div>
        )
    }

}
export default TeacherProfile;
